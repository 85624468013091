<template>
  <Toast />
  <router-view />
</template>

<script lang="ts">
import { defineComponent, nextTick, onMounted } from "vue";
import { useStore } from "vuex";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { initializeComponents } from "@/core/plugins/keenthemes";
// import { loadProgressBar } from 'axios-progress-bar'
import Toast from 'primevue/toast';
import { useToast } from "primevue/usetoast";
export default defineComponent({
  name: "app",
  components: {
    Toast
  },
  setup() {
    const store = useStore();
    const toast = useToast();

    onMounted(() => {
      document.addEventListener("visibilitychange", () => {
        if (document.visibilityState === "visible") {
          window['isTabActive'] = true;
        } else {
          window['isTabActive'] = false;
        }
      });

      navigator.serviceWorker.addEventListener('message', (event) => {
        if (event.data.type == 'notif') {
          toast.add({ severity: event.data.icon, summary: event.data.title, detail: event.data.message, life: 3000 })
        }

        if (event.data.isStore) {
          event.data.store.forEach(item => {
            if (event.data.params) {
              let params = event.data.params
              store.dispatch(item, { params })
            } else {
              store.dispatch(item)
            }
          });
        }
      })
      // loadProgressBar()
      if (window.location.search.search('transaction_status') != -1) {
        window.location.href = "/#/history_payment"
      }

      const myMidtransClientKey = process.env.VUE_APP_CLIENT_KEY_MIDTRANS;
      const script = document.createElement('script');
      script.src = process.env.VUE_APP_SNAP_URL;
      script.setAttribute('type', '')
      script.setAttribute('data-client-key', myMidtransClientKey);
      script.async = true;
      document.body.appendChild(script);
      if (localStorage.getItem('refresh_token')) {
        store.dispatch(Actions.SET_ROLE);
      }
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

      nextTick(() => {
        initializeComponents();
      });
    });
  },
});


</script>

<style lang="scss">
#nprogress .spinner-icon {
  display: none
}


.p-dropdown-items {
  padding-left: 0px;
  padding-left: 0px;
}

.swal-container {
  z-index: 9999;
  /* Atur ke nilai yang cukup tinggi agar muncul di atas dialog PrimeVue */
}

@import "~axios-progress-bar/dist/nprogress.css";
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~apexcharts/dist/apexcharts.css";
@import "~quill/dist/quill.snow.css";
@import "~animate.css";
@import "~sweetalert2/dist/sweetalert2.css";
@import "~nouislider/distribute/nouislider.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~socicon/css/socicon.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "~dropzone/dist/dropzone.css";
@import "~@vueform/multiselect/themes/default.css";
@import "~prism-themes/themes/prism-shades-of-purple.css";
@import "~element-plus/dist/index.css";

// Main demo style scss
@import "assets/sass/plugins";
@import "assets/sass/style";

//RTL version styles
//@import "assets/css/style.rtl.css";</style>
