import { createApp } from "vue";
import App from "./App.vue";
import Tooltip from 'primevue/tooltip';
import router from "./router";
import store from "./store";

import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";
import PrimeVue from 'primevue/config';
import GoogleSignInPlugin from "vue3-google-signin"
import ToastService from 'primevue/toastservice';
import VueJsTour from '@globalhive/vuejs-tour';
import ConfirmationService from 'primevue/confirmationservice';
import Ripple from 'primevue/ripple';
import Dialog from 'primevue/dialog';
import FocusTrap from 'primevue/focustrap';
import ConfirmDialog from 'primevue/confirmdialog';
import VueCookies from 'vue-cookies';
// Imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import "@/core/plugins/prismjs";
import "bootstrap";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import '@globalhive/vuejs-tour/dist/style.css';

const app = createApp(App);

app.use(GoogleSignInPlugin, {
  clientId: '1052634980293-umam046q48msjsf757loe5m23pc80oaq.apps.googleusercontent.com',
});

app.use(store);
app.use(router);
app.use(ElementPlus, {
  size: 'small',
  zIndex: 3000
});
app.directive('tooltip', Tooltip);
app.directive('focustrap', FocusTrap);
app.use(PrimeVue, { ripple: true });
app.component('Dialog', Dialog);
app.component('ConfirmDialog', ConfirmDialog)
app.use(ToastService);
app.use(ConfirmationService)
app.directive('ripple', Ripple);
app.use(VueJsTour);
app.use(VueCookies);
ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/service-worker.js').then(async () => {
    const registration = await navigator.serviceWorker.ready;
    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(process.env.VUE_APP_PUBLIC_KEY_WEB_PUSH)
    });
    const params = subscription
    ApiService.post('user_subscribe', { params })
  })
}

export const urlBase64ToUint8Array = (base64String) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
  const rawData = atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

if (navigator.serviceWorker.controller) {
  navigator.serviceWorker.controller.postMessage({ type: 'SKIP_WAITING' });
}

app.use(i18n);

app.mount("#app");
