import ApiService from "@/core/services/ApiService";


const state = {
    projectGroup: [],
    orderGroupData: []
}


const mutations = {
    setProjectGroup(state, projectGroups) {
        state.projectGroup = projectGroups
    },
    setOrderGroup(state, orderGroup) {
        state.orderGroupData = orderGroup
    }
}

const actions = {
    getListProject({ commit }, params) {
        return ApiService.query("project/get_order_group", params).then((data) => {
            commit('setProjectGroup', data.data.data)
            return data.data
        }).catch(error => {
            return error
        })
    },
    getListOrderGroup({ commit }, params) {
        return ApiService.query('get_order_group', params).then(response => {
            commit('setOrderGroup', response.data.data.rows)
            return response.data
        }).catch(error => {
            return error
        })
    },
}


export default {
    state,
    mutations,
    actions
}